<script setup lang="ts">
// This is to prevent trying to bind a file to the file input, which leads to a
// " Failed to set the 'value' property on 'HTMLInputElement':".

const { value } = defineProps<{ value: ContentFile | undefined }>()

const emits = defineEmits<{
  (event: 'update:modelValue', value?: FileList | null): void
}>()

const imageSelected = (event: Event) => {
  const target = event.target as HTMLInputElement
  const files = target.files
  if (!files) return
  emits('update:modelValue', files)
}
</script>

<template>
  <div class="border-1 border-muted-300 w-full rounded border p-2">
    
    
    <div v-if="value?.fileUrl">
      <label class="nui-label w-full pb-2 text-sm">Huidige afbeelding:</label>
      <img
        :src="value.fileUrl"
        class="my-3 max-h-32"
        alt=""
      >
      <TextParagraph size="sm" color="light">
        Vervangen:
      </TextParagraph>
    </div>

    <label v-else class="nui-label w-full pb-2 text-sm">Afbeelding:</label>
    <input type="file" @change="imageSelected">
  </div>
</template>
